import React, { useEffect, useState } from 'react';
import Footer from './Footer';
import * as styles from './Layout.module.scss';
import { SHOULD_RUN_GOOGLE_OPTIMIZE } from '../../constants';
import shadowMobile from '../../images/shadowMobile.png';
import shadowDesktop from '../../images/shadowDesktop.png';
import videoShadow from '../../videos/film.mp4';
import { clsx } from '../../utils/utils';
import poster from '../../images/videoPoster.png';

const USE_VIDEO = true;

interface LayoutProps {
  children: React.ReactNode;
}

const Layout = ({ children }: LayoutProps): React.ReactElement => {
  const [isHidden, setIsHidden] = useState(SHOULD_RUN_GOOGLE_OPTIMIZE);
  // Initialize google optimize experiment on 'optimize.activate'
  useEffect(() => {
    if (SHOULD_RUN_GOOGLE_OPTIMIZE) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({ event: 'optimize.activate' });
      setIsHidden(false);
    }
  }, []);

  return (
    <div style={isHidden ? { opacity: 0 } : undefined}>
      <main className={styles.main}>
        {children}
        {USE_VIDEO && (
          <>
            <video
              src={videoShadow}
              autoPlay
              muted
              playsInline
              loop
              poster={poster}
              disablePictureInPicture
              id="bgvid"
              className={styles.video}
            ></video>
          </>
        )}
        {!USE_VIDEO && (
          <>
            <img src={shadowMobile} alt="" className={styles.shadowMobile} />
            <img src={shadowDesktop} alt="" className={styles.shadowDesktop} />
          </>
        )}
      </main>
      <Footer></Footer>
    </div>
  );
};

export default Layout;
