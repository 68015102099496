/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React from 'react';
import { graphql } from 'gatsby';
import BlockContent from '@sanity/block-content-to-react';

import Layout from '../../components/ui/Layout';
import { replaceNewLinesWithBr } from '../../utils/utils';
import '../../fragments.ts';
import PageSEO from '../../components/PageSEO';
import { wrapSquareBracketedInSpan } from '../../utils/utils';
import logo from '../../images/logo.svg';
import { RawPortableText } from '../../types/types';
import serializers from '../../serializers';
import PortalForm from '../../components/ui/PortalForm';

import '../../styles/main.scss';
import * as styles from './index.module.scss';

export const query = graphql`
  fragment Homepage on SanityHomepage {
    homeScreen {
      title
      _rawText
    }
    birthDataScreen {
      title
      introText
    }
    resultScreen {
      signSectionTitle
      knowMoreTitle
      knowMoreText
    }
    shareScreen {
      reportSectionTitle
      _rawReportSectionText
      shareSectionTitle
      shareSectionText
    }
  }
  {
    sanityHomepage {
      ...Homepage
    }
    sanityVedicRisingSigns {
      ...VedicRisingSigns
    }
  }
`;

interface VedicSign {
  name: string;
  introPhrase: string;
  text: string;
}

export interface VedicRisingSigns {
  asvini: VedicSign;
  bharani: VedicSign;
  krittika: VedicSign;
  rohini: VedicSign;
  mrgasirsa: VedicSign;
  ardra: VedicSign;
  punarvasu: VedicSign;
  pusya: VedicSign;
  aslesa: VedicSign;
  magha: VedicSign;
  purvaPhalguni: VedicSign;
  uttaraPhalguni: VedicSign;
  hasta: VedicSign;
  chitra: VedicSign;
  svati: VedicSign;
  vishakha: VedicSign;
  anuradha: VedicSign;
  jyestha: VedicSign;
  mula: VedicSign;
  purvaAsadha: VedicSign;
  uttaraAsadha: VedicSign;
  sravana: VedicSign;
  sravistha: VedicSign;
  satabhisa: VedicSign;
  purvaBhadrapada: VedicSign;
  uttaraBhadrapada: VedicSign;
  revati: VedicSign;
}

export interface BirthdayScreen {
  title: string;
  introText: string;
}

export interface ResultScreen {
  signSectionTitle: string;
  knowMoreTitle: string;
  knowMoreText: string;
}

export interface ShareScreen {
  reportSectionTitle: string;
  _rawReportSectionText: RawPortableText;
  shareSectionTitle: string;
  shareSectionText: string;
}
export interface IndexPageProps {
  data: {
    sanityHomepage: {
      homeScreen: {
        title: string;
        _rawText: RawPortableText;
      };
      birthDataScreen: BirthdayScreen;
      resultScreen: ResultScreen;
      shareScreen: ShareScreen;
    };
    sanityVedicRisingSigns: VedicRisingSigns;
  };
}

export type Screen = 'home' | 'result' | 'share';

const IndexPage = ({ data }: IndexPageProps): React.ReactElement => {
  const { homeScreen, birthDataScreen, resultScreen, shareScreen } = data.sanityHomepage;

  return (
    <Layout>
      <PageSEO />
      <div className={styles.index}>
        <div className={styles.contentContainer}>
          <div className={styles.textContainer}>
            <div className={styles.logoContainer}>
              <img src={logo} alt="" />
            </div>
            <h1 className={styles.title}>
              {wrapSquareBracketedInSpan(homeScreen.title, replaceNewLinesWithBr)}
            </h1>
            <BlockContent
              className={styles.text}
              blocks={homeScreen._rawText}
              renderContainerOnSingleChild
              serializers={serializers}
            />
          </div>
          <PortalForm
            birthdayScreen={birthDataScreen}
            resultScreen={resultScreen}
            shareScreen={shareScreen}
            vedicRisingSigns={data.sanityVedicRisingSigns}
          />
        </div>
      </div>
    </Layout>
  );
};

export default IndexPage;
