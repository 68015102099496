import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { useLocation } from '@reach/router';
import { useTranslation } from 'react-i18next';
import { ALL_LANGS, SITE_BASE_URL } from '../constants';
import { LocalizedSEO } from '../fragments';
import { localize } from '../utils/sanity';
import SEO from './SEO';

interface LocalizedQueryData {
  site: {
    siteMetadata: {
      author: string;
    };
  };
  sanityHomepage: {
    seo: LocalizedSEO;
  };
}

interface PageSEOProps {
  defaultTitle?: string;
  defaultDescription?: string;
  defaultImageUrl?: string;
  pageSEO?: {
    title?: string;
    shareTitle?: string;
    description?: string;
    image?: {
      asset: {
        url: string;
      };
    };
  };
}

function PageSEO({
  defaultTitle,
  defaultDescription,
  defaultImageUrl,
  pageSEO,
}: PageSEOProps): React.ReactElement {
  const websiteSeoData = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          author
        }
      }
      sanityHomepage {
        seo {
          ...SEOWebsite
        }
      }
    }
  `);
  const location = useLocation();
  const { i18n } = useTranslation();
  const langs = [i18n.language, ...ALL_LANGS.filter(lang => lang !== i18n.language)];
  const {
    site: {
      siteMetadata: { author },
    },
    sanityHomepage: { seo: websiteSEO },
  } = localize(websiteSeoData, langs) as LocalizedQueryData;
  const pageTitle = pageSEO?.title || defaultTitle;
  const pageShareTitle = pageSEO?.shareTitle || pageTitle;
  const websiteTitle = websiteSEO?.title;
  const websiteShareTitle = websiteSEO?.shareTitle || websiteTitle;

  return (
    <SEO
      title={pageTitle ? pageTitle + ' - ' + websiteTitle : websiteTitle}
      shareTitle={pageShareTitle ? pageShareTitle + ' - ' + websiteShareTitle : websiteShareTitle}
      description={pageSEO?.description || defaultDescription || websiteSEO?.description}
      url={`${SITE_BASE_URL}${location.pathname}`}
      imageUrl={pageSEO?.image?.asset.url || defaultImageUrl || websiteSEO?.image?.asset.url}
      lang={i18n.language}
      author={author}
    />
  );
}

export default PageSEO;
